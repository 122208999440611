body {
  margin: 0;
  font-family: 'Helvetica Neue';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ant-typography, h1, h2, h3, h4, h5, h6, li, span, a, tr, td, th, ant-table-cell, header, button {
  font-family: 'Helvetica Neue' !important;

}

.ant-menu-inline.ant-menu-root .ant-menu-item>.ant-menu-title-content {
  font-family: 'Helvetica Neue' !important;

}

